import { AngularComponent } from '../../angular-core';
import { HttpService } from '../../services/http.service';
import { ValidatorService } from '../../services/validator.service';
import { ZipcodeService } from '../../services/zipcode.service';

/**
 * フォーム
 */
@AngularComponent({
  bindings: {},
  template: require('./form.component.html'),
  styles: require('./form.component.scss')
})
export class FormComponent {

  /**
   * 送信できるかの真偽値
   */
  public submit: boolean = false;

  /**
   * エラーオブジェクト
   */
  public error: any = {};

  /**
   * フォームオブジェクト
   */
  public form: any = {};

  /**
   * 要素
   */
  private readonly dom: JQuery = $('form-component');

  /**
   * コンストラクタ
   */
  constructor(
    private readonly $scope: any,
    private readonly $timeout: any,
    private readonly httpService: HttpService,
    private readonly validatorService: ValidatorService,
    private readonly zipcodeService: ZipcodeService
  ) { }

  /**
   * 初期化
   */
  public $onInit(): void {
    this.validatorService.init({
      'full_name': this.validatorService.isValid,
      'email': this.validatorService.isMail,
      'company': this.validatorService.isValid,
      'location': this.validatorService.isValid,
      'industry': this.validatorService.isValid,
      'employees': this.validatorService.isValid,
      'agree': this.validatorService.isValid
    });

    this.httpService.getFormData()
    .then((response: any): void => {
      this.form = (response && response.data) ? response.data : {};
      this.$scope.$watch('$ctrl.form', (newValue: any, oldValue: any): void => {
        this.createError();
        this.event();
      });
    });
  }

  /**
   * エラーオブジェクト作成
   */
  private createError(): void {
    this.dom.each((index: number, element: Element): void => {
      $('input, textarea, select', element).not('[type="button"], [type="submit"]').each((index: number, element: Element): void  => {
        this.validate($(element), true);
      });
    });
  }

  /**
   * イベント
   */
  private event(): void {
    this.dom.each((index: number, element: Element): void => {
      $('input, textarea', element).not('[type="checkbox"], [type="radio"], [type="button"], [type="submit"]').each((index: number, element: Element): void => {
        $(element).on('focusout', (event: JQueryEventObject): void => {
          this.validate($(event.currentTarget));
        });
      });
      $('input[type="checkbox"], input[type="radio"], select', element).each((index: number, element: Element): void => {
        $(element).on('focusout change', (event: JQueryEventObject): void => {
          this.validate($(event.currentTarget));
        });
      });
      $('select').each((index: number, element: Element): void => {
        const $target: JQuery = $(element);
        $target.css('color', $target.val() ? '#000' : '#c7c6bd')
        .on('focusout change', (): void => {
          $target.css('color', $target.val() ? '#000' : '#c7c6bd');
        });
      });
    });
    this.dom.parents('form').on('submit', this.submitListener);
  }

  /**
   * バリデート
   * @param $target バリデートの対象となる要素
   * @param isFirst 最初の読み込みかどうか
   */
  private validate($target: JQuery, isFirst: boolean = false): void {
    const name: string = $target.attr('name');
    const value: string = this.value(name);
    const data: {[name: string]: boolean} = this.validatorService.validate({
      name: name.replace('[]', ''),
      value: value
    });

    for (let key in data) {
      this.$timeout((): void => {
        this.error[key] = (isFirst && ! data[key]) ? '' : data[key];
      }, 1);
    }

    if (name == 'zip' || name == 'zipcode') {
      this.zipcodeService.search(value, (address: string): void => {
        this.$timeout((): void => {
          this.form.address = address;
        }, 1);
      });
    }

    this.check();
  }

  /**
   * 送信できるか確認
   */
  private check(): void {
    this.$timeout((): void => {
      if (! this.error) {
        this.submit = false;
      } else {
        let flag: boolean = true;

        for (let key in this.error) {
          const error: any = this.error[key];
          const $target: JQuery = $('#' + key);

          if (error === '' || error === false) {
            flag = false;

            if (error === false) {
              $target.addClass('is-error');
            }
          } else {
            $target.removeClass('is-error');
          }
        }

        this.submit = flag;
      }
    }, 1);
  }

  /**
   * 指定したname属性の値を取得
   * @param name name属性の名前
   * @returns 指定したname属性の値
   */
  private value(name: string): string {
    const $target: JQuery = $(`[name="${name}"]`);
    const type: string = $target.attr('type');

    if (type && (type == 'checkbox' || type == 'radio')) {
      return $target.map((): string | null => {
        if ($target.is(':checked')) {
          return $target.val();
        }

        return null;
      }).get().toString();
    } else {
      return $target.val();
    }
  }

  /**
   * サブミットイベントリスナー
   * @param event イベントオブジェクト
   */
  private submitListener = (event: JQueryEventObject): boolean => {
    this.dom.each((index: number, element: Element): void => {
      $('input, textarea', element).not('[type="checkbox"], [type="radio"], [type="button"], [type="submit"]').each((num: number, target: Element): void => {
        this.validate($(target));
      });
      $('input[type="checkbox"], input[type="radio"], select', element).each((num: number, target: Element): void => {
        this.validate($(target));
      });
    });
    this.check();

    if (this.submit === false) {
      $('html, body').animate({scrollTop: this.dom.offset()!.top - $('.fn-header').height()}, 300);
      return false;
    }

    return true;
  }

}
