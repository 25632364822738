import 'slick-carousel';

/**
 * slickスライダー
 */
export class SlickSlider {

  /**
   * コンストラクタ
   * @param selector 対象の要素セレクタ
   * @param option slickオプション
   */
  constructor(selector: string, option: any) {
    (<any>$(selector)).slick(option);
  }

}
