import { AngularService } from '../angular-core';

/**
 * 住所検索サービス
 */
@AngularService({
  name: 'zipcodeService'
})
export class ZipcodeService {

  /**
   * キャッシュオブジェクト
   */
  private static readonly cache: {[name: string]: {[name: string]: string}} = {};

  /**
   * リクエストURL
   */
  private readonly requestURL: string = 'https://yubinbango.github.io/yubinbango-data/data';

  /**
   * 都道府県リスト
   */
  private readonly preList: string[] = [
    '', '北海道', '青森県', '岩手県', '宮城県',
    '秋田県', '山形県', '福島県', '茨城県', '栃木県',
    '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県',
    '新潟県', '富山県', '石川県', '福井県', '山梨県',
    '長野県', '岐阜県', '静岡県', '愛知県', '三重県',
    '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県',
    '和歌山県', '鳥取県', '島根県', '岡山県', '広島県',
    '山口県', '徳島県', '香川県', '愛媛県', '高知県',
    '福岡県', '佐賀県', '長崎県', '熊本県', '大分県',
    '宮崎県', '鹿児島県', '沖縄県'
  ];

  /**
   * 指定した郵便番号から住所を検索
   * @param zip 指定する郵便番号
   * @param callback コールバック関数
   */
  public search(zip: string, callback: (address: string) => void): void {
    zip = zip.replace(/-/g, '').replace(/ー/g, '').replace(/ /g, '').replace(/　/g, '');
    zip = zip.replace(/[０-９]/g, function(str: string): string {
      return String.fromCharCode(str.charCodeAt(0) - 65248);
    });
    const head: string = zip.substr(0, 3);

    if (ZipcodeService.cache[head] && ZipcodeService.cache[head][zip]) {
      callback(ZipcodeService.cache[head][zip]);
    } else if (head) {
      this.jsonp(`${this.requestURL}/${head}.js`, (data: any): void => {
        const address: string[] = data[zip];

        if (! ZipcodeService.cache[head]) {
          ZipcodeService.cache[head] = {};
        }

        if (address && address[0] && address[1]) {
          const result: string = this.preList[parseInt(address[0])] + address[1] + address[2] + (address[3] ? address[3] : '');
          ZipcodeService.cache[head][zip] = result;
          callback(result);
        } else {
          ZipcodeService.cache[head][zip] = '';
          callback('');
        }
      });
    }
  }

  /**
   * 指定したURLのスクリプトを読み込む
   * @param url 指定するURL
   * @param callback コールバック関数
   */
  public jsonp(url: string, callback: any): void {
    const script: Element = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('charset', 'UTF-8');
    script.setAttribute('src', url);
    document.head.appendChild(script);
    (<any>window).$yubin = (data: any) => callback(data);
  }

}
