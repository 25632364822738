import { AngularExecution } from './angular-core';
import { FormComponent } from './components/form/form.component';

/**
 * Angular App
 */
@AngularExecution({
  'formComponent': FormComponent
})
export class AngularApp { }
