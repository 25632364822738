/**
 * 設定
 */
export class Setting {

  /**
   * SPの最大横幅
   */
  public static readonly spWidth: number = 767;

  /**
   * ヘッダーがSPになる時のブレイクポイント
   */
  public static readonly headerBreakPoint: number = 860;

  /**
   * 画面のサイズがSPかどうか調べる
   * @returns SPの場合にtrue
   */
  public static isSp(): boolean {
    return $(window).innerWidth() <= Setting.spWidth;
  }

  /**
   * 画面のサイズがヘッダーがSPになる時かどうか調べる
   * @returns ヘッダーがSPになる場合にtrue
   */
  public static isHeaderBreakPoint(): boolean {
    return $(window).innerWidth() <= Setting.headerBreakPoint;
  }

}
