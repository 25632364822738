/**
 * ヘッダーがscrollされた時小さくなる(PCサイズのみ)
 */
export class HeaderCompact {

  /**
   * windowオブジェクト
   */
  private readonly $window: JQuery;

  /**
   * header オブジェクト
   */
  private headerElem!: JQuery;

  /**
   * コンストラクタ
   * @param element 対象の要素セレクタ
   * @param speed 移動スピード
   */
  constructor(element: string) {
    this.$window = $(window);
    this.headerElem = $(element);

    this.scrollHeader();
  }

  /**
   * scrollした時ヘッダー変形
   */
  private scrollHeader = (): void => {
    this.$window.scroll(() => {
      if (this.$window.scrollTop() > this.$window.height() / 2) {
        this.headerElem.addClass('is-active');
      } else {
        this.headerElem.removeClass('is-active');
      }
    });
  }

}
