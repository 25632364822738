import * as angular from 'angular';
import 'angular-sanitize';

/**
 * Angularモジュール
 */
export const AngularModule: ng.IModule = angular.module('App', ['ngSanitize'])
.config(function($httpProvider: ng.IHttpService): ng.IModule {
  $httpProvider.defaults.transformRequest = function(data: ng.IModule): any {
    if (data === undefined) {
      return data;
    }

    return $.param(data);
  };

  if ($httpProvider.defaults.headers) {
    $httpProvider.defaults.headers.post = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    };
  }

  return AngularModule;
});

/**
 * 実行デコレータ
 */
export const AngularExecution = (components: {[name: string]: any}): any => {
  return <T extends {new(...args: any[]): {}}>(target: T): void => {
    for (let key in components) {
      AngularModule.component(key, new components[key]());
    }

    new target();
  };
};

/**
 * コンポーネントデコレータ
 */
export const AngularComponent = (option: {bindings: any, template: string, styles: string}): any => {
  return <T extends {new(...args: any[]): {}}>(target: T): any => {
    return class extends target implements ng.IComponentOptions {
      public bindings: any;
      public template: string;
      public styles: string;
      public controller: any;

      constructor(...args: any[]) {
        super(args);
        this.bindings = option.bindings;
        this.template = option.template;
        this.styles = option.styles;
        this.controller = target;
        this.template += `<style>${this.styles}</style>`;
      }
    };
  };
};

/**
 * サービスデコレータ
 */
export const AngularService = (option: {name: string}): any => {
  return <T extends {new(...args: any[]): {}}>(target: T): void => {
    AngularModule.service(option.name, target);
  };
};
