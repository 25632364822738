/**
 * セレクトボックスでページ遷移
 */
export class SelectBoxLink {

  /**
   * コンストラクタ
   * @param target 対象の要素セレクタ
   * @param label ラベル表示セレクタ
   */
  constructor(target: string, label: string) {
    const $target: JQuery = $(target);
    const $label: JQuery = $(label);

    if (! $target[0]) {
      return;
    }

    $target.each((index: number, element: Element): void => {
      const $element: JQuery = $(element);
      let $current: JQuery = $element.find('option:selected');

      $label.text($current.text());

      $element.on('change', (): void => {
        if ($element.val() != '') {
          window.location.href = $element.val();
          $current = $element.find('option:selected');

          $label.text($current.text());
        }
      });
    });
  }

}
