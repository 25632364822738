/**
 * ページ内スクロール
 */
export class SmoothScroll {

  /**
   * 対象の要素オブジェクト
   */
  private readonly $element: JQuery;

  /**
   * オプション
   */
  private readonly option: SmoothScrollOption;

  /**
   * コンストラクタ
   * @param element 対象の要素セレクタ
   * @param option オプション
   */
  constructor(element: string, option: SmoothScrollOption = {
    speed: 300,
    disableScroll: 'fn-smoothScroll__disabled',
    adjustPx: - $('.fn-header').height()
  }) {
    this.$element = $(element);
    this.option = option;
    this.init();
  }

  /**
   * 初期化
   */
  private init = function(this: SmoothScroll): void {
    this.$element.each((index: number, element: Element): void => {
      $(element).on('click', this.clickListener);
    });
  }

  /**
   * クリックイベントリスナー
   * @param event イベントオブジェクト
   */
  private clickListener = (event: JQueryEventObject): void => {
    const $this = $(event.currentTarget);

    if ($this.hasClass(this.option.disableScroll)) {
      return;
    }

    event.preventDefault();
    this.onScroll($this);
  }

  /**
   * スクロール開始
   * @param $element 対象の要素オブジェクト
   */
  private onScroll = ($element: JQuery): void => {
    let position: number = 0;

    if ($element.attr('href') == '#top') {
      position = 0;
    } else {
      const $target: JQuery = $($element.attr('href'));

      if (! $target[0]) {
        return;
      }

      position = $target.offset()!.top;

      if (this.option.adjustPx) {
        position += this.option.adjustPx;
      }
    }

    $('html, body').animate({
      scrollTop: position
    }, this.option.speed);
  }

}

/**
 * オプション
 */
export interface SmoothScrollOption {

  /**
   * スクロールスピード
   */
  speed: number;

  /**
   * スムーススクロールを無効にするクラス名
   */
  disableScroll: string;

  /**
   * スクロール位置の調整ピクセル数（jQueryによる高さ取得実行も可能）
   */
  adjustPx: number;

}
