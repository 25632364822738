/**
 * @requires jquery
 * @requires jquery.query
 * @requires extend.js
 * @type {*}
 */
eventCalendar.search = (function(){
  var query;
  var year;
  var month;
  var day;
  var isSearchConnpass;

  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  // public methods
  function init(params){
    params = params || {};

    query = params.query || "";
    year = params.year;
    month = params.month;
    day = params.day;
    isSearchConnpass = params.isSearchConnpass;

    $("#query").val(query.replace(/\+/g," "));
    $("#year").text(year);
    $("#month").text(addZero(month));
    $("#day").text(day);

    $("#checkConnpass").check(isSearchConnpass);
  }

  function validate(params){
    params = params || {};

    var today = new Date();
    if(!eventCalendar.util.isParam(params.year)){
      params.year = today.getFullYear();
    }
    if(!eventCalendar.util.isParam(params.month)){
      params. month = today.getRealMonth();
    }
    if(!eventCalendar.util.isParam(params.day)){
      params.day = today.getDate();
      var monthEndDay = eventCalendar.util.getMonthEndDay(params.year, params.month);
      if(params.day > monthEndDay){
        params.day = monthEndDay;
      }
    }
    if(!eventCalendar.util.isParam(params.query)){
      params.query = "";
    }

    params.year = params.year * 1;
    params.month = params.month * 1;
    params.day = params.day * 1;
    params.isSearchConnpass = eventCalendar.util.isSearch(params.isSearchConnpass);
    return params;
  }

  function currentDate(){
    return new Date(year, month-1, day);
  }

  function checkValue(id){
    if($('#'+id).attr('checked')){
      return "1";
    } else{
      return "0";
    }
  }

  function createSearchParam(params){
    params = params || {};

    var q = $("#query").val();

    var co = this.checkValue("checkConnpass");

    var query = $.query.set("co", co);
    if(params.year && params.month){
      query = query.set("y", params.year).set("m", params.month);
    } else{
      query = query.set("y", year).set("m", month);
    }

    if(params.day){
      query = query.set("d", params.day);
    }
    return query.toString();
  }

  function searchAllMonthly(){
    var params = {
      query : query,
      year : year,
      month : month
    };
    return $.when(
      isSearchConnpass ? eventCalendar.event.connpass.searchMonthly(params) : null
    );
  }

  function searchAllMonthlyLite(){
    var params = {
      query : query,
      year : year,
      month : month,
      dayUnique : true
    };
    var df = $.Deferred();

    $.when(
      // can use dayUnique
      isSearchZusaar ? eventCalendar.event.zusaar.searchMonthly(params) : null,
      isSearchKokucheese ? eventCalendar.event.kokucheese.searchMonthly(params) : null,
      isSearchDoorkeeper ? eventCalendar.event.doorkeeper.searchMonthly(params) : null
    ).done(function(){
        params.days = eventCalendar.main.getRemainingDays({year: year, month:month});
        if(!params.days){
          df.resolve();
          return;
        }
        params.count = 1;

        $.when(
          isSearchAtnd ? eventCalendar.event.atnd.searchEachDays(params) : null
        ).done(function(){
            params.days = eventCalendar.main.getRemainingDays({year: year, month:month});
            if(!params.days){
              df.resolve();
              return;
            }

            $.when(
              isSearchConnpass ? eventCalendar.event.connpass.searchEachDays(params) : null
            ).done(function(){
                df.resolve();
              });
           });
      });

    return df.promise();
  }

  function searchAllDaily(){
    var params = {
      query : query,
      year : year,
      month : month,
      day : day
    };
    return $.when(
      isSearchConnpass ? eventCalendar.event.connpass.searchDaily(params) : null
    );
  }

  return {
    init : init,
    validate : validate,
    currentDate : currentDate,
    checkValue : checkValue,
    createSearchParam: createSearchParam,
    searchAllMonthly : searchAllMonthly,
    searchAllMonthlyLite : searchAllMonthlyLite,
    searchAllDaily : searchAllDaily
  };

  // private methods

}());
