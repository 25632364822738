/**
 * エンジニアカフェのバナー
 */
export class CafeFixed {

  /**
   * コンストラクタ
   * @param element 対象の要素セレクタ
   */
  constructor(element: string) {
    if (! $(element)[0]) {
      return;
    }
    
    $(window).on('scroll', (): void => {
      const scrollHeight: number = $(document).height();
      const scrollPosition: number = $(window).height() + $(window).scrollTop();
      const transitionTime = 300;

      if (((scrollHeight - scrollPosition) / scrollHeight) <= 0.03) {
        $(element).fadeOut(transitionTime);
      } else {
        $(element).fadeIn(transitionTime);
      }
    });
  }
}
