/**
 * ヘッダーがscrollされた時小さくなる(PCサイズのみ)
 */
export class HeaderSelect {

  /**
   * windowオブジェクト
   */
  private readonly $window: JQuery;

  /**
   * header オブジェクト
   */
  private headerElem!: JQuery;

  /**
   * header オブジェクト
   */
  private headerLeaveElem!: JQuery;

  /**
   * targetオブジェクト
   */
  private targetElem!: JQuery;

  /**
   * コンストラクタ
   * @param element 対象の要素セレクタ
   */
  constructor(element: string, leave: string, target: string) {
    this.$window = $(window);
    this.headerElem = $(element);
    this.headerLeaveElem = $(leave);
    this.targetElem = $(target);

    this.scrollTarget();
  }

  /**
   * scrollした時ヘッダー変形
   */
  private scrollTarget = (): void => {
    this.$window.scroll(() => {
      if (this.targetElem.length) {
        if (this.$window.scrollTop() > this.targetElem.offset()!.top) {
          this.headerElem.addClass('is-current');
          this.headerLeaveElem.removeClass("is-current");
        } else {
          this.headerElem.removeClass('is-current');
          this.headerLeaveElem.addClass("is-current");
        }
      }
    });
  }

}
