import 'jquery.easing';
import 'jquery-query-object';
import './calendar';
import './event';
import './extend';
import './search';
import './util';

eventCalendar.main = (function(){
  // public methods
  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
  function addEvent(params, icon){
    var event = params.event;
    var day = eventCalendar.util.parseDate(event.started_at).getDate();
    var li = $("<li/>").addClass(icon).addClass(params.kind);

    var d1 = new Date(event.started_at);
    var h1 = addZero(d1.getHours());
    var m1 = addZero(d1.getMinutes());

    var d2 = new Date(event.ended_at);
    var h2 = addZero(d2.getHours());
    var m2 = addZero(d2.getMinutes());

    $("<p/>").text(h1 + ":" + m1 + "-" + h2 + ":" + m2).appendTo(li);

    // $("<p/>").text(event.started_at + "~" + event.ended_at).appendTo(li);

    $("<a/>").addClass("event").
      attr({href: event.event_url, target: "_blank", alt: event.title, title: event.title}).
      text(event.title).
      appendTo(li);

    if(params.kind == "owner" || params.kind == "user"){
      if(existsEvent(day, event.event_url)){
        // 既に登録されていたら上の方に表示するために古いのを削除する
        $("ul.event li a[href='"+ event.event_url +"']").parent("li").remove();
      }

      // 自分に関係するイベントは先頭に挿入
      $("#allDay" + day + " ul.event").prepend(li);
      $("#day" + day + " ul.event").prepend(li.clone(true));
    } else{
      // その他のイベントは末尾に挿入(既に同一URLがある場合には挿入しない)
      if(!existsEvent(day, event.event_url)){
        $("#allDay" + day + " ul.event").append(li);

        var count = $("#allDay" + day + " ul.event li.other").length;
        if(count <= 3){
          $("#day" + day + " ul.event").append(li.clone(true));
        }
      }
    }

    var count = $("#allDay" + day + " ul.event li.other").length;
    if(count == 3 && $("#dayCount"+day).length == 0 ){
      (function(d){
        var button = $("<button/>")
          .addClass("btn")
          .click(function(){
            // 高さ調整
            var windowHeight = eventCalendar.util.getBrowserHeight() * 0.9;
            $("#allDay" + d).dialog("open");
            var dialogHeight = $("#allDay" + d).height();
            var options = {position : "center"};
            if(dialogHeight > windowHeight){
              options.height = windowHeight;
            }
            $("#allDay" + d).dialog("close").dialog(options).dialog("open");
            return false;
          }).text("All");
        $("#day" + d)
          .append(button)
          .append(
          $("<span/>").attr("id", "dayCount" + d)
        );

        $("#allDay" + d)
          .attr("title", eventCalendar.search.currentDate().getRealMonth() + "月" + d + "日のイベント")
          .css("white-space", "nowrap")
          .dialog({
            autoOpen: false,
            width : 800,
            zIndex : 50
          });
      })(day);
    }

    if(count >= 4){
      $("#dayCount" + day).text(count + "件");
    }

    siteCounts = siteCounts || {};
    siteCounts[icon] = siteCounts[icon] || 0;
    totalCount = totalCount || 0;

    ++siteCounts[icon];
    ++totalCount;
  }

  function dispTotal(){
    $("#total").text(totalCount);
    dispSiteCount("#countConnpass", "connpass");
  }

  function clearCount(){
    totalCount = 0;
    siteCounts = {};
  }

  function searchForward(params){
    var query = eventCalendar.search.createSearchParam(params);

    if (localStorage.length > 10) {
      localStorage.clear();
    }

    localStorage.setItem("query", query);
  }

  function getQueryParams(url) {
    var arg = {};
    var pair = url.split('?')[1].split("&");

    for (var i = 0; pair[i]; i++) {
      var kv = pair[i].split("=");
      arg[kv[0]] = kv[1];
    }

    return arg;
  }

  function initialize(isFirst) {
    $('#prevMonth, #nextMonth').hide();

    if (! localStorage.getItem("query")) {
      var params = eventCalendar.search.validate({
        query: $.query.get("q"),
        year: $.query.get("y"),
        month: $.query.get("m"),
        day: $.query.get("d"),
        isSearchConnpass: $.query.get("co")
      });
    } else {
      var query = localStorage.getItem("query");
      var queryObj = eventCalendar.main.getQueryParams(query);
      params = eventCalendar.search.validate({
        query: queryObj.q,
        year: queryObj.y,
        month: queryObj.m,
        day: queryObj.d,
        isSearchConnpass: queryObj.co
      });
    }

    eventCalendar.calendar.init({year: params.year, month: params.month});
    eventCalendar.search.init(params);
    $(".day").removeClass("line");

    eventCalendar.search.searchAllMonthly().always(function() {
      $(".day:has(.connpass)").addClass("line");
      $('#prevMonth, #nextMonth').show();

      if (isFirst) {
        initializeOnReady(false);
      }
    });

    $(".day > span").on("click", function() {
      if ($(this).parent(".day").hasClass("line")) {
        $(".events").hide();
        $(this).next(".events").show();
        $(".day > span").removeClass("is-show");
        $(this).addClass("is-show");
      }
    });

    $(".close").on("click", function() {
      $(".day > span").removeClass("is-show");
      $(".events").hide();
    });
  }

  function initializeOnReady(isFuture) {
    const date = new Date();
    date.setMonth(isFuture ? date.getMonth() + 1 : date.getMonth() -1);
    _doAjax(date)();
  }

  function _doAjax(date) {
    return function() {
      var ym = eventCalendar.util.toYYYYMM(date.getFullYear(), date.getMonth() + 1);
      // var eventData = localStorage.getItem("event_data_" + ym);
      // var eventExpires = localStorage.getItem("event_expires_" + ym);

      /*if (eventData && eventExpires > new Date().getTime() - 43200000) {
        return;
      }*/

      $.ajax({
        cache: false,
        url: location.href,
        traditional : true,
        async : true,
        type: "POST",
        timeout: 30000,
        data: {
          mode: "event",
          format: "json",
          start: 1,
          count: 500,
          allReturn : false,
          dayUnique : false,
          ym: ym,
          _year: date.getFullYear(),
          _month: date.getMonth() + 1,
          lastDay: new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
        },
        dataType: "json",
        success: function(response, status){

          if (localStorage.length > 10) {
            localStorage.clear();
          }

          localStorage.setItem("event_data_" + ym, JSON.stringify(response));
          localStorage.setItem("event_expires_" + ym, new Date().getTime());
        }
      });
    }
  }

  return {
    addEvent : addEvent,
    dispTotal: dispTotal,
    clearCount : clearCount,
    searchForward : searchForward,
    getQueryParams: getQueryParams,
    initializeOnReady: initializeOnReady,
    initialize: initialize
  };

  // private methods
  function dispSiteCount(target, name){
    var count = 0;
    if(siteCounts && siteCounts[name]){
      count = siteCounts[name];
    }
    $(target).text( "("+count+")" );
  }

  function existsEvent(day, event_url){
    return $("#allDay" + day + " ul.event li a[href='"+ event_url +"']").length > 0
  }

  function yymm(str){
    var date = eventCalendar.util.parseDate(str);
    if(date){
      return date.getRealMonth() + "/" + date.getDate();
    }
    return "";
  }

  var totalCount;
  var siteCounts;
}());

eventCalendar.event.connpass.addCallback = function(params){
  eventCalendar.main.addEvent(params, "connpass");
};
