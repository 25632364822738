/**
 * @requires jquery
 * @requires extend.js
 * @type {*}
 */
window.eventCalendar = {};
eventCalendar.calendar = (function(){
  function addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }
  // public methods
  function init(params){
    var year = params.year;
    var month = params.month;

    $("#calendar tr.week td").attr("id", "").text("");

    var date = new Date(year, month-1, 1);
    var day = 1;
    var weekIndex = 0;
    while(date.getRealMonth() == month){
      var dayOfWeek = date.getDay();

      $("#calendar tr.week:eq("+weekIndex+") td:eq("+dayOfWeek+")")
        .removeClass("padding")
        .attr("id", "day"+day)
        .addClass("day")
        .append($("<span/>").text(addZero(day)))
        .append(
        $("<div/>")
          .addClass("events")
          .append($("<div/>").addClass("close"))
          .append($("<ul/>").addClass("event"))
        );

      date = date.addDate(1);
      day++;

      if(dayOfWeek == 6 && date.getRealMonth() == month){
        weekIndex++;
      }
    }

    if(weekIndex < 5){
      //$("#calendar tr.week:eq(5)").hide();
    }
    if(weekIndex < 4){
      //$("#calendar tr.week:eq(4)").hide();
    }

    $(".day").removeClass("today");
    var today = new Date();
    if(month == today.getRealMonth()){
      $("#day"+today.getDate()).addClass("today");
    }
  }

  return {
    init : init
  };

  // private methods
  function padding(value){
    return (value < 10) ? "0"+value : value;
  };

}());
