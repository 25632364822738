import { AngularService } from '../angular-core';

/**
 * バリデーターサービス
 */
@AngularService({
  name: 'validatorService'
})
export class ValidatorService {

  /**
   * コールバックオブジェクト
   */
  public callbacks: {[name: string]: Function} = {};

  /**
   * 初期化
   * @param callbacks コールバックオブジェクト
   */
  public init = (callbacks: {[name: string]: Function}): void => {
    this.callbacks = callbacks;
  }

  /**
   * バリデート実行
   * @param request 判定するオブジェクト
   * @returns 結果バリデートオブジェクト
   */
  public validate = (request: {name: string, value: string}): {[name: string]: boolean} => {
    const data: {[name: string]: boolean} = {};
    const callback: Function = this.callbacks[request.name];
    data[request.name] = callback ? callback(request.value) : true;
    return data;
  }

  /**
   * 指定した文字列が空文字でないか判定
   * @param value 判定する文字列
   * @returns 空文字でない場合にtrueを返します。
   */
  public isValid = (value: string): boolean => {
    value = value.replace(/ /g, '');
    value = value.replace(/　/g, '');
    return value != '';
  }

  /**
   * 指定した文字列が空文字でなく、指定した文字数以内かどうか判定
   * @param value 判定する文字列
   * @param length 指定する文字数
   * @returns 空文字でなく、指定した文字数以内の場合にtrueを返す
   */
  public isMessage = (value: string, length: number = 2000): boolean => {
    if (! this.isValid(value)) {
      return false;
    }

    return value.length <= length;
  }

  /**
   * 指定した文字列がメールアドレスかどうかを判定
   * @param value 判定する文字列
   * @returns メールアドレスの場合にtrueを返す
   */
  public isMail = (value: string): boolean => {
    if (! this.isValid(value)) {
      return false;
    }

    const regexp: RegExp = /^[-+.\w]+@[-a-z0-9]+(\.[-a-z0-9]+)*\.[a-z]{1,100}$/i;
    return regexp.test(value);
  }

  /**
   * 指定した文字列がURLかどうかを判定
   * @param value 判定する文字列
   * @returns URLの場合にtrueを返す
   */
  public isURL = (value: string): boolean => {
    if (! this.isValid(value)) {
      return false;
    }

    const regexp: RegExp = /^(https?|ftp)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)$/;
    return regexp.test(value);
  }

  /**
   * 指定した文字列全てがカタカナかどうかを判定
   * @param value 指定する文字列
   * @returns 全てがカタカナの場合にtrueを返す
   */
  public isKana = (value: string): boolean => {
    if (! this.isValid(value)) {
      return false;
    }

    const regexp: RegExp = /^[\u30A0-\u30FF]+$/;
    return regexp.test(value);
  }

  /**
   * 指定した文字列が数字かどうかを判定
   * @param value 判定する文字列
   * @returns 数字の場合にtrueを返す
   */
  public isNumber = (value: string): boolean => {
    if (! this.isValid(value)) {
      return false;
    }

    const regexp: RegExp = /^[0-9]+$/;
    return regexp.test(value);
  }

  /**
   * 指定した文字列が電話番号かどうかを判定
   * @param value 判定する文字列
   * @returns 電話番号の場合にtrueを返す
   */
  public isTel = (value: string): boolean => {
    if (! this.isValid(value)) {
      return false;
    }

    value = value.replace(/-/g, '');

    if (value.length < 10) {
      return false;
    }

    return this.isNumber(value);
  }

  /**
   * 指定した文字列が郵便番号かどうかを判定
   * @param value 判定する文字列
   * @returns 郵便番号の場合にtrueを返す
   */
  public isZip = (value: string): boolean => {
    if (! this.isValid(value)) {
      return false;
    }

    value = value.replace(/-/g, '');

    if (value.length != 7) {
      return false;
    }

    return this.isNumber(value);
  }

  /**
   * 指定した文字列が半角英数字かどうかを判定
   * @param value 判定する文字列
   * @returns 半角英数字の場合にtrueを返す
   */
  public isAlphanumeric = (value: string): boolean => {
    if (! this.isValid(value)) {
      return false;
    }

    const regexp: RegExp = /^[a-zA-Z0-9]+$/;
    return regexp.test(value);
  }

}
