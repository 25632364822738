import './modules/event-calender';

/**
 * カレンダーイベント実行
 */
(function() {
  if ($('#calendar').length) {

    if (localStorage.length) {
      localStorage.clear();
    }

    eventCalendar.main.initialize(true);

    $('#prevMonth').on('click', function() {
      var date = eventCalendar.search.currentDate().addMonth(-1);
      eventCalendar.main.searchForward({year: date.getFullYear(), month: date.getRealMonth()});
      eventCalendar.main.initialize();
    });

    $('#nextMonth').on('click', function() {
      var date = eventCalendar.search.currentDate().addMonth(1);
      eventCalendar.main.searchForward({year: date.getFullYear(), month: date.getRealMonth()});
      eventCalendar.main.initialize();
    });

    if (localStorage.getItem('query') != null) {
      $(window).on('beforeunload', function() {
        localStorage.removeItem('query');
      });
    }
  }
})();
