/**
 * モジュール読み込み
 */
import '../js/main';
import './modules/angularjs/angular-app';

import { Execution } from './modules/utility/Decorator';
import { HeaderSlideDown } from './modules/layout/HeaderSlideDown';
import { MoreBtn } from './modules/widget/MoreBtn';
import { PageTop } from './modules/scroll/PageTop';
import { SelectBoxLink } from './modules/widget/SelectBoxLink';
import { SlickSlider } from './modules/widget/SlickSlider';
import { SmoothScroll } from './modules/scroll/SmoothScroll';
import { TelLink } from './modules/text/TelLink';
import { WpPagenavi } from './modules/widget/WpPagenavi';
import { HeaderSelect } from './modules/scroll/HeaderSelect';
import { HeaderCompact } from './modules/scroll/HeaderCompact';
import { CafeFixed } from './modules/scroll/CafeFixed';
import { ModalBtn } from './modules/widget/ModalBtn';
import { DetailImgLink } from './modules/widget/DetailImgLink';
import { SaveScroll } from './modules/scroll/SaveScroll';

/**
 * 実行クラス
 */
@Execution()
class Main {

  /**
   * コンストラクタ
   */
  constructor() {
    /**
     * ヘッダースライドダウン
     */
    new HeaderSlideDown('.fn-navTrigger', '.fn-navInner');

    /**
     * ページトップ
     */
    new PageTop('.fn-pageTop');

    /**
     * Moreボタン
     */
    new MoreBtn('.fn-moreBtn', '.fn-moreBtnItem');

    /**
     * 電話番号テキストにリンクを追加
     */
    new TelLink('.fn-telLink');

    /**
     * ページ内リンク
     */
    new SmoothScroll('.fn-smoothScroll:not(.fn-pageTop)');

    /**
     * セレクトボックスでページ遷移
     */
    new SelectBoxLink('.fn-selectLink', '.fn-selectLinkLabel');

    /**
     * Wp Pagenavi書き換え
     */
    new WpPagenavi('.wp-pagenavi');

    /**
     * slickスライダー
     */
    //TOP インフォメーション
    new SlickSlider('.fn-infoSlider', {
      vertical: false,
      verticalSwiping: true,
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      easing: 'easeInOutExpo',
      draggable: true,
    });

    //TOP インタビュー
    new SlickSlider('.fn-interviewSlider', {
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: '<span class="slick-next">&gt;&gt;</span>',
      prevArrow: '<span class="slick-prev">&lt;&lt;</span>',
    });

    //TOP サポーター
    new SlickSlider('.fn-supporterSlider', {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 6000
    });

    /**
     * scrollでヘッダー変形
     */
    new HeaderCompact('.fn-headerCompact');

    /**
     * scrollでヘッダー選択切り替え
     */
    new HeaderSelect(
      ".fn-headerSelect",
      ".fn-headerSelectLeave",
      ".fn-headerSelectTarget"
    );

    /**
     * エンジニアカフェのバナー
     */
    new CafeFixed('.fn-linkCafe');

    /**
     * 賛同企業ページのモーダル
     */
    new ModalBtn('.fn-modalBtn');

    /**
     * 詳細ページ画像寄せ対応
     */
    new DetailImgLink();

    /**
     * 検索位置保存
     */
    new SaveScroll('.fn-saveScroll')
  }

}
