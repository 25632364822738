/**
 * モーダル（モーダルの表示/非表示）
 */
export class ModalBtn {

  /**
   * コンストラクタ
   * @param item 対象のセレクタ
   */
  constructor(item: string) {
    const $item: JQuery = $(item);

    if (! $item[0]) {
      return;
    }

    const enCafeBottom: number = parseInt($('.fn-linkCafe').css('bottom'));

    $item.on('click', (event: JQueryEventObject) => {
      const target = $(event.currentTarget).next(item + '__modal')[0];
      $(target).addClass('is-active');
      $(target).scrollTop(0);
      const widthBefore: number = $('body').width();
      $('body').css({'overflow-y': 'hidden'});
      const widthAfter: number = $('body').width();

      if (Math.abs(widthAfter - widthBefore) > 0) {
        $('body, header').css({'padding-right': Math.abs(widthAfter - widthBefore) + 'px'});
        $('.fn-modalCafe').css({'right': Math.abs(widthAfter - widthBefore) + 'px'});
      }
    });

    $(item + '__close').on('click', (event: JQueryEventObject) => {
      $(event.currentTarget).parents('.is-active').removeClass('is-active');
      $('body').css({'overflow-y': 'visible'});
      $('body, header').css({'padding-right': 0});
      $('.fn-modalCafe').css({'right': 0});
    });
  }
}
