import { SmoothScroll } from './SmoothScroll';

/**
 * ページトップへ戻る
 */
export class PageTop {

  /**
   * 対象の要素オブジェクト
   */
  private readonly $element: JQuery;

  /**
   * windowオブジェクト
   */
  private readonly $window: JQuery;

  /**
   * 移動スピード
   */
  private readonly speed: number = 0;

  /**
   * windowの高さ
   */
  private winHeight: number = 0;

  /**
   * timeoutID
   */
  private timeoutID: any = 0;

  /**
   * コンストラクタ
   * @param element 対象の要素セレクタ
   * @param speed 移動スピード
   */
  constructor(element: string, speed: number = 300) {
    this.$element = $(element);
    this.$window = $(window);

    if (! this.$element[0]) {
      return;
    }

    new SmoothScroll('.fn-pageTop');
    this.winHeight = this.getWinHeight();
    this.speed = speed;
    this.$window.on('resize', this.resizeListener);
    // this.$window.on('scroll', this.scrollListener);
  }

  /**
   * windowの高さを取得
   * @returns windowの高さ
   */
  private getWinHeight(): number {
    return (window.innerHeight) ? window.innerHeight : this.$window.height();
  }

  /**
   * リサイズイベントリスナー
   * @param event イベントオブジェクト
   */
  private resizeListener = (event: JQueryEventObject): void => {
    clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout((): void => {
      this.winHeight = this.getWinHeight();
    }, 80);
  }

  /**
   * スクロールイベントリスナー
   * @param event イベントオブジェクト
   */
  private scrollListener = (event: JQueryEventObject): void => {
    if (this.$window.scrollTop() > this.winHeight / 2) {
      this.$element.fadeIn(this.speed);
    } else {
      this.$element.fadeOut(this.speed);
    }
  }

}
