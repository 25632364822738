/**
 * 投稿本文内画像寄せ対応
 */
export class DetailImgLink {

  /**
   * コンストラクタ
   * @param selector 対象の要素セレクタ
   */
  constructor() {
    const centerImg: any = document.querySelectorAll('img');
    centerImg!.forEach((elem: Element) => {
      if (elem.parentElement!.outerHTML.startsWith('<a')) {
        if (elem.className.indexOf('aligncenter') != -1) {
          elem.parentElement!.outerHTML = "<div class='link-wrap is-center'>" + elem.parentElement!.outerHTML + "</div>";
          elem.parentElement!.style.textAlign = 'center';
        }

        if (elem.className.indexOf('alignright') != -1) {
          elem.parentElement!.outerHTML = "<div class='link-wrap is-right'>" + elem.parentElement!.outerHTML + "</div>";
          elem.parentElement!.style.textAlign = 'right';
        }

        if (elem.className.indexOf('alignleft') != -1) {
          elem.parentElement!.outerHTML = "<div class='link-wrap is-left'>" + elem.parentElement!.outerHTML + "</div>";
          elem.parentElement!.style.textAlign = 'left';
        }
      }
    });
  }
}