/**
 * トグルボタン（要素の表示/非表示）
 */
export class MoreBtn {

  /**
   * コンストラクタ
   * @param btn トリガーセレクタ
   * @param item 対象のアイテムセレクタ
   */
  constructor(btn: string, item: string) {
    const $btn: JQuery = $(btn);

    if (! $btn[0]) {
      return;
    }

    $btn.each((index: number, element: Element): void => {
      const $element: JQuery = $(element);
      const $count: number = $element.siblings(item).length;
      let $num: number = 1;

      if ($count <= 1) {
        $element.fadeOut();
      }

      $element.on('click', (): void => {
        $element.siblings(item).eq($num).fadeIn();

        if ($num == $count - 1) {
          $element.fadeOut();
        } else {
          ++$num;
        }
      });
    });
  }

}
