
/**
 * ページトップへ戻る
 */
export class SaveScroll {

  /**
   * 対象の要素オブジェクト
   */
  private readonly $element: JQuery;

  /**
   * windowオブジェクト
   */
  private readonly $window: JQuery;

  /**
   * コンストラクタ
   * @param element 対象の要素セレクタ
   */
  constructor(element: string) {
    this.$element = $(element);
    this.$window = $(window);

    if (! this.$element) {
      return;
    }

    this.getScroll();
  }

  /**
   * スクロール量取得
   */
  public getScroll(): any {
    this.$element.submit((event) => {
      this.$element.children('input[name=scroll]').attr('value', this.$window.scrollTop());
    });
  }
}
