/**
 * Wp Pagenavi書き換え
 */
export class WpPagenavi {

  /**
   * コンストラクタ
   * @param selector 対象の要素セレクタ
   */
  constructor(selector: string) {
    $(selector).each((index: number, element: Element): void => {
      const $list: JQuery = $('<ul>').addClass('c-nav-paged');
      const $prev: JQuery = $('<li>').addClass('c-nav-paged__item c-nav-paged__item--prev');
      let $middle: JQuery = $('<li>').addClass('c-nav-paged__item c-nav-paged__item--middle');
      const $next: JQuery = $('<li>').addClass('c-nav-paged__item c-nav-paged__item--next');

      $middle.append($('<ul>'));
      $list.append($prev).append($middle).append($next);
      $middle = $middle.find('ul');

      $(element).children().each((i: number, e: Element): void => {
        const $item: JQuery = $(e);
        const href: string = $item.attr('href');
        const text: string = $item.html();

        if ($item.hasClass('previouspostslink')) {
          $prev.append(`<a class="c-nav-paged__btn c-nav-paged__btn--prev" href="${href}"><span>${text}</span></a>`);
        }

        if ($item.hasClass('page')) {
          $middle.append(`<li class="c-nav-paged__item"><a class="c-nav-paged__link" href="${href}"><span>${text}</span></a></li>`);
        }

        if ($item.hasClass('last') || $item.hasClass('first')) {
          $middle.append(`<li class="c-nav-paged__item pc-only"><a class="c-nav-paged__link" href="${href}"><span>${text}</span></a></li>`);
        }

        if ($item.hasClass('current')) {
          $middle.append(`<li class="c-nav-paged__item"><span class="c-nav-paged__link is-current">${text}</span></li>`);
        }

        if ($item.hasClass('extend')) {
          $middle.append(`<li class="c-nav-paged__item"><span>...</span></li>`);
        }

        if ($item.hasClass('nextpostslink')) {
          $next.append(`<a class="c-nav-paged__btn c-nav-paged__btn--next" href="${href}"><span>${text}</span></a>`);
        }
      });

      $(element).replaceWith($list);
    });
  }

}
