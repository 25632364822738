import { Setting } from '../utility/Setting';

/**
 * 電話番号テキストにSP時リンクを追加
 */
export class TelLink {

  /**
   * コンストラクタ
   * @param target 対象の要素セレクタ
   */
  constructor(target: string) {
    $(target).each(function(index: number, element: Element): void {
      const $this: JQuery = $(element);
      $this.data('tel_link', new TelLinkObject($this));
    });
  }

}

/**
 * 実行クラス
 */
class TelLinkObject {

  /**
   * 対象の要素オブジェクト
   */
  private readonly $element: JQuery;

  /**
   *  windowオブジェクト
   */
  private readonly $window: JQuery;

  /**
   *  a要素オブジェクト
   */
  private readonly $markUp: JQuery;

  /**
   * 電話番号
   */
  private readonly phoneNumber: string;

  /**
   * windowの横幅
   */
  private winWidth: number = 0;

  /**
   * コンストラクタ
   * @param element 対象の要素オブジェクト
   */
  constructor($element: JQuery) {
    this.$element = $element;
    this.$window = $(window);
    this.$markUp = $('<a/>');
    this.phoneNumber = this.getPhoneNumber();
    this.$markUp.attr('href', 'tel:' + this.phoneNumber);
    this.$window.on('load resize', this.setLinkListener);
  }

  /**
   * 電話番号取得
   * @returns 電話番号
   */
  private getPhoneNumber(): string {
    return this.$element.text();
  }

  /**
   * リンクの設定
   * @param event イベントオブジェクト
   */
  private setLinkListener = (event: JQueryEventObject): void => {
    this.winWidth = this.$window.innerWidth();

    switch (this.winWidth <= Setting.spWidth) {
      case true:
        // a要素がある場合は終了
        if (this.$element.children('a').length) {
          break;
        }

        this.$element.wrapInner(this.$markUp);
        break;
      case false:
        // a要素がない場合は終了
        if (! this.$element.children('a').length) {
          break;
        }

        this.$element.children('a').remove();
        this.$element.text(this.phoneNumber);
        break;
      default:
        break;
    }
  }

}
