/**
 * ヘッダースライドダウン
 */
export class HeaderSlideDown {

  /**
   * コンストラクタ
   * @param btn トリガーセレクタ
   * @param target 対象のアイテムセレクタ
   */
  constructor(btn: string, target: string) {
    const $btn: JQuery = $(btn);
    const $target: JQuery = $(target);

    if (! $btn[0]) {
      return;
    }
    
    $btn.each((index: number, element: Element): void => {
      const $element: JQuery = $(element);

      $element.on('click', (): void => {
        $btn.toggleClass('is-active');
        $target.toggleClass('is-active');

        if (!$target.hasClass('is-active')) {
          $target.slideUp(150);
        } else {
          $target.slideDown(300);
        }
      });
    });
  }

}
