import { AngularService } from '../angular-core';

/**
 * HTTPサービス
 */
@AngularService({
  name: 'httpService'
})
export class HttpService {

  /**
   * コンストラクタ
   */
  constructor(
    private readonly $http: any
  ) { }

  /**
   * フォームデータを取得
   * @returns HttpPromiseオブジェクト
   */
  public getFormData(): ng.IHttpPromise<any> {
    return this.$http({
      method: 'POST',
      url: location.href,
      responseType: 'json',
      data: {
        mode: 'form_data'
      }
    });
  }

  /**
   * 投稿数を取得
   * @param params リクエストデータ
   * @returns HttpPromiseオブジェクト
   */
  public getFoundPosts(params: any): ng.IHttpPromise<any> {
    return this.$http({
      method: 'POST',
      url: location.href,
      responseType: 'json',
      data: params
    });
  }

  /**
   * 投稿一覧を取得
   * @param params リクエストデータ
   * @param paged ページ数
   * @returns HttpPromiseオブジェクト
   */
  public getPosts(params: any, paged: number): ng.IHttpPromise<any> {
    return this.$http({
      method: 'POST',
      url: `${location.href}/page/${paged}`,
      responseType: 'json',
      data: params
    });
  }

}
